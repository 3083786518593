<script setup lang="ts">
import { watch, ref } from 'vue';
import { Hub } from 'aws-amplify/utils';
import { BASE_TITLE } from '~/utilities/constants';

useHead({
  title: `Login ${BASE_TITLE}`,
  meta: [{ property: 'og:title', content: `Login ${BASE_TITLE}` }],
});

const route = useRoute();
const router = useRouter();

const { $Amplify } = useNuxtApp();

const redirectRoute = Array.isArray(route.query?.redirect)
  ? route.query?.redirect[0]
  : route.query?.redirect || '/user/profiles';

const userState = ref();
const userAttributes = ref();
try {
  userState.value = await $Amplify.Auth.getCurrentUser();
  userAttributes.value = await $Amplify.Auth.fetchUserAttributes();
  console.log('userAttributes', userAttributes.value);
} catch (error) {
  userState.value = undefined;
  userAttributes.value = undefined;
}

const username = ref('');
const password = ref('');
const verificationCode = ref('');

const signInError = ref('');

const pending = ref(false);

const passwordResetState = ref(Array.isArray(route.query.reset) ? route.query.reset[0] : route.query.reset);
watch(passwordResetState, (reset) => {
  router.replace({
    path: route.path,
    query: { reset },
  });
});

async function submitForm() {
  if (passwordResetState.value === 'request') {
    await forgotPasswordSendCode();
  } else if (passwordResetState.value === 'confirm') {
    await forgotPasswordSubmit();
  } else {
    await signIn();
  }
}

async function signIn() {
  if (pending.value) {
    return;
  }
  pending.value = true;
  signInError.value = '';
  try {
    try {
      await $Amplify.Auth.signIn({
        username: username.value.toLowerCase(),
        password: password.value,
      });
      userState.value = await $Amplify.Auth.getCurrentUser();
      userAttributes.value = await $Amplify.Auth.fetchUserAttributes();
    } catch (e) {
      if (e.name === 'NotAuthorizedException') {
        await $Amplify.Auth.signIn({ username: username.value, password: password.value });
        userState.value = await $Amplify.Auth.getCurrentUser();
        userAttributes.value = await $Amplify.Auth.fetchUserAttributes();
      } else {
        throw e;
      }
    }
  } catch (e) {
    if (e.name === 'NotAuthorizedException') {
      signInError.value = e.message;
    } else if (e.name === 'UserNotConfirmedException') {
      signInError.value = e.message;
    } else {
      console.log('error signing in: ', e);
    }
  } finally {
    pending.value = false;
  }
}

const forgotPasswordMsg = ref({ msg: '', error: false });

async function forgotPasswordSendCode() {
  if (pending.value) {
    return;
  }
  pending.value = true;
  try {
    forgotPasswordMsg.value = { error: undefined, msg: 'Sending password reset code...' };
    const data = await $Amplify.Auth.resetPassword({ username: username.value });
    forgotPasswordMsg.value = { error: false, msg: 'Code send to ' + data.nextStep.codeDeliveryDetails.destination };
    passwordResetState.value = 'confirm';
  } catch (e) {
    signInError.value = e.message;
    forgotPasswordMsg.value = { error: true, msg: e.message };
  } finally {
    pending.value = false;
  }
}

const forgotPasswordResetMsg = ref({ msg: '', error: false });

async function forgotPasswordSubmit() {
  if (pending.value) {
    return;
  }
  pending.value = true;
  try {
    forgotPasswordResetMsg.value = { error: undefined, msg: 'Resetting password...' };
    await $Amplify.Auth.confirmResetPassword({
      username: username.value,
      confirmationCode: verificationCode.value,
      newPassword: password.value,
    });
    await $Amplify.Auth.signIn({ username: username.value, password: password.value });
  } catch (e) {
    forgotPasswordResetMsg.value = { error: true, msg: e.message };
  } finally {
    pending.value = false;
  }
}

if (process.client) {
  const stopListening = Hub.listen('auth', ({ payload }) => {
    const { event } = payload;
    console.log({ payload });

    if (event === 'signedIn') {
      userState.value = payload.data;
    }
  });
  onBeforeUnmount(() => stopListening());
}

function forwardRoute() {
  console.log('Checking route forward');
  if (redirectRoute !== undefined) {
    if (userState.value !== undefined) {
      console.log('Forwarding route');
      router.replace(redirectRoute);
    }
  }
}

forwardRoute();

watch(userState, forwardRoute);
</script>

<template>
  <div class="pt-4 md:pt-8">
    <Container>
      <div v-if="!userState" class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <img class="mx-auto h-12 w-auto" src="/mint-logo-round.svg" alt="MintGarden Logo" />
          <h2
            v-if="passwordResetState"
            class="mt-6 text-center text-3xl font-bold tracking-tight text-neutral-900 dark:text-neutral-50"
          >
            {{ $t('login.resetAccount') }}
          </h2>
          <h2 v-else class="mt-6 text-center text-3xl font-bold tracking-tight text-neutral-900 dark:text-neutral-50">
            {{ $t('login.signInAccount') }}
          </h2>
          <p class="mt-2 text-center text-sm text-neutral-600 dark:text-neutral-300">
            {{ $t('login.or') }}
            <a
              v-if="passwordResetState"
              href="#"
              class="font-medium text-emerald-600 hover:text-emerald-500"
              @click.prevent="passwordResetState = undefined"
            >
              {{ $t('login.tryLogin') }}
            </a>
            <NuxtLink v-else to="/register" class="font-medium text-emerald-600 hover:text-emerald-500">
              {{ $t('login.registerNewAccount') }}
            </NuxtLink>
          </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white px-4 py-8 dark:bg-black sm:px-10">
            <form class="space-y-6" @submit.prevent="submitForm">
              <div>
                <label for="email" class="block text-sm font-medium text-neutral-700 dark:text-neutral-200">
                  {{ $t('login.emailAddress') }}
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    v-model="username"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-black focus:outline-none focus:ring-black dark:border-neutral-600 dark:bg-neutral-900 dark:text-white dark:placeholder-neutral-500 dark:focus:border-neutral-400 dark:focus:ring-neutral-400 sm:text-sm"
                  />
                </div>
              </div>

              <div v-if="passwordResetState !== 'request'">
                <label for="password" class="block text-sm font-medium text-neutral-700 dark:text-neutral-200">
                  {{ $t('login.password') }}
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-black focus:outline-none focus:ring-black dark:border-neutral-600 dark:bg-neutral-900 dark:text-white dark:placeholder-neutral-500 dark:focus:border-neutral-400 dark:focus:ring-neutral-400 sm:text-sm"
                  />
                </div>
              </div>

              <div v-if="passwordResetState === 'confirm'">
                <label for="verificationCode" class="block text-sm font-medium text-neutral-700 dark:text-neutral-200">
                  {{ $t('login.verificationCode') }}
                </label>
                <div class="mt-1">
                  <input
                    id="verificationCode"
                    v-model="verificationCode"
                    name="verificationCode"
                    type="text"
                    autocomplete="verificationCode"
                    required
                    class="block w-full appearance-none rounded-md border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-black focus:outline-none focus:ring-black dark:border-neutral-600 dark:bg-neutral-900 dark:text-white dark:placeholder-neutral-500 dark:focus:border-neutral-400 dark:focus:ring-neutral-400 sm:text-sm"
                  />
                </div>
              </div>

              <div v-if="!passwordResetState" class="flex items-center justify-between">
                <div class="text-sm">
                  <a
                    href="#"
                    class="font-medium text-emerald-600 hover:text-emerald-500"
                    @click.prevent="passwordResetState = 'request'"
                  >
                    {{ $t('login.forgotPassword') }}</a
                  >
                </div>
              </div>

              <div class="pt-2">
                <button
                  type="submit"
                  class="text-md betterhover:hover:shadow-card-hover flex w-full justify-center rounded-full border border-transparent bg-black px-4 py-2 font-medium text-white transition duration-100 ease-in-out dark:bg-white dark:text-black betterhover:hover:-translate-y-0.5"
                >
                  <svg
                    v-if="pending"
                    class="-ml-1 mr-3 h-5 w-5 animate-spin text-white dark:text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {{ passwordResetState ? $t('login.resetPassword') : $t('login.signIn') }}
                </button>
              </div>
              <div v-if="signInError !== ''" class="pt-2 text-sm text-red-600">
                {{ signInError }}
              </div>
              <div v-if="forgotPasswordResetMsg?.msg" class="pt-2 text-sm text-neutral-600 dark:text-neutral-300">
                {{ forgotPasswordResetMsg.msg }}
              </div>
              <div v-if="forgotPasswordResetMsg?.error" class="pt-2 text-sm text-red-600">
                {{ forgotPasswordResetMsg.error }}
              </div>
            </form>
          </div>
        </div>

        <div>
          <AccountFeatures />
        </div>
      </div>

      <div v-else-if="userAttributes" class="mx-auto max-w-md border dark:border-neutral-700">
        <div class="text-neutral-900 dark:text-neutral-50">
          {{ $t('register.welcome', { email: userAttributes.email }) }} {{ $t('register.redirecting') }}
        </div>

        <div v-if="redirectRoute !== undefined">
          <NuxtLink :to="redirectRoute">
            <span class="text-blue-500 underline hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300">{{
              $t('register.redirectHelp')
            }}</span>
          </NuxtLink>
        </div>
      </div>
    </Container>
  </div>
</template>
